import React from 'react';
import { Box, Button, Dialog } from '@mui/material';
import styled from '@emotion/styled';

import { ReactComponent as CloseIcon } from './../assets/icons/CloseIcon.svg';
import { ReactComponent as TickSquare } from './../assets/icons/TickSquare.svg';

const ConfirmDialog = styled(Dialog)({
    '.MuiBackdrop-root': {
        backgroundColor: '#0000',
    },
    '.MuiDialog-paper': {
        backgroundColor: '#141539',
        padding: '56px 40px',
        borderRadius: '40px',
    },
});

const CloseIconContainer = styled(Box)({
    position: 'absolute',
    top: '16px',
    right: '8px',
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.8,
    }
});

const TickSquareContainer = styled(Box)({
    alignSelf: 'center',
    borderRadius: '1000px',
    boxShadow: '0px 20px 40px -10px rgba(0, 255, 221, 0.3)',
    marginTop: '-8px',
});

const DialogTitle = styled('p')({
    color: '#fff',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '-0.7200000286102295px',
    textAlign: 'center',
    margin: '0px',
    marginTop: '32px',
});

const DialogSubtitle = styled('p')({
    color: '#fff',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    textAlign: 'center',
    maxWidth: '245px',
    margin: '0px',
    marginTop: '16px',
});

const ConfirmButton = styled(Button)({
    alignSelf: 'center',
    backgroundColor: '#3B4BF9',
    fontWeight: '700',
    fontSize: '16px',
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'none',
    marginTop: '32px',
    padding: '24px 48px',
    width: '195px',
    borderRadius: '14px',
    '&:hover': {
        backgroundColor: '#3B4BF9aa',
    }
});

const ConfirmationDialog = ({ open, onClose }) => {
    return (
        <ConfirmDialog open={open} onClose={onClose}>
            <CloseIconContainer onClick={onClose}>
                <CloseIcon />
            </CloseIconContainer>
            <TickSquareContainer>
                <TickSquare />
            </TickSquareContainer>
            <DialogTitle>
                Boom. Avi Created.
            </DialogTitle>
            <DialogSubtitle>
                Your avi is ready to use on stream, you can go back to Twitch.
            </DialogSubtitle>
            <ConfirmButton onClick={onClose}>
                Understood
            </ConfirmButton>
        </ConfirmDialog>
    );
}

export default ConfirmationDialog;