import { useEffect, useRef, useState } from 'react';
import { Button, Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { saveAvatarId, saveAvatarUrl, saveReadyPlayerMeUserId } from '../../services/database';
import { useAuth } from '../../AuthProvider';

import SecondPanel from '../../components/Hub/SecondPanel'
import ThirdPanel from '../../components/Hub/ThirdPanel'

import avatarHeading from '../../assets/AvatarHeading.png';
import illustration from '../../assets/Illustration.png';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const AvatarIFrame = styled('iframe')({
    flex: 1,
    margin: '-48px',
    border: 'none',
    borderRadius: '20px'
});

const AvatarHeading = styled('img')({
    maxWidth: '205px',
});

const Illustration = styled('img')({
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    maxWidth: '50%',
});

const CreateAvatarContainer = styled(Box)({
    position: 'absolute',
    bottom: '48px',
    left: '48px'
});

const CreateAvatarText = styled('p')({
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFF',
    whiteSpace: 'break-spaces'
});

const CreateAvatarButton = styled(Button)({
    marginTop: '40px',
    backgroundColor: '#0AFFD2',
    borderRadius: '14px',
    padding: '24px 48px',
    boxShadow: '0px 20px 40px -10px rgba(0, 255, 221, 0.2)',
    fontSize: '16px',
    fontWeight: '700',
    color: '#0D1021',
    '&:hover': {
        backgroundColor: '#0AFFD2',
        opacity: '.9'
    }
});

const SecondPanelBlur = styled(Box)({
    position: 'absolute',
    top: -10,
    right: -10,
    left: -10,
    bottom: -10,
    backdropFilter: 'blur(25px)',
    backgroundColor: '#0005',
});

const ThirdPanelTitle = styled('p')({
    color: '#fff',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
});

const ThirdPanelSubtitle = styled('p')({
    color: '#fff',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
});

const Avatar = () => {
    const [createAvatar, setCreateAvatar] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const iFrameRef = useRef(null);
    const { t } = useTranslation('translation', { keyPrefix: 'Avatar' })
    const user = useAuth();

    useEffect(() => {
        window.addEventListener('message', subscribe);
        document.addEventListener('message', subscribe);

        return () => {
            window.removeEventListener('message', subscribe);
            document.removeEventListener('message', subscribe);
        }
    });

    const subscribe = (event) => {
        const json = parse(event);
        if (json?.source !== 'readyplayerme') {
            return;
        }

        /**
         * Subscribe to all events sent from Ready Player Me once frame is ready
         */
        if (json.eventName === 'v1.frame.ready') {
            const iFrame = iFrameRef.current
            if (iFrame && iFrame.contentWindow) {
                iFrame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**'
                    }),
                    '*'
                );
            }
        }

        // Get avatar GLB URL
        if (json.eventName === 'v1.avatar.exported') {
            onAvatarExported(json.data.url, json.data.userId);
        }
    }

    const parse = (event) => {
        try {
          return JSON.parse(event.data);
        } catch (error) {
          return null;
        }
    }

    const onAvatarExported = async (url, rpmUid) => {
        /**
         * url is always: https://models.readyplayer.me/{avatarId}.glb
         * so we extract the avatarId by doing a substring from the last '/' index + 1 and
         * then removing the '.glb'
         * As it is a route to a file the last '/' is before the {avatarId}.glb even if there are changes
         * on the base url, and also can not be nothing else after the '.glb' because it is a rout to a file
         */
        const avatarId = url.substring(url.lastIndexOf('/') + 1).replace('.glb', '');
        await saveAvatarId(user.uid, avatarId);
        await saveAvatarUrl(user.uid, `https://api.readyplayer.me/v1/avatars/${avatarId}.glb`);
        if (rpmUid) {
            await saveReadyPlayerMeUserId(user.uid, rpmUid);
        }

        setConfirmDialogOpen(true);
    }

    const userHasAvatar = user && user.avatarId;

    return (
        <>
        <SecondPanel>
            {/* User undefined means we don´t load the user auth status yet */}
            {user !== undefined ?
                userHasAvatar ?
                    <AvatarIFrame src='https://qapla.readyplayer.me/hub'
                        title='Ready Player Me Hub' />
                    :
                    createAvatar ?
                        <AvatarIFrame src='https://qapla.readyplayer.me/avatar?frameApi'
                            allow='camera *; microphone *'
                            title='Avatar Creator'
                            ref={iFrameRef} />
                        :
                        <>
                        <AvatarHeading src={avatarHeading} />
                        <Illustration src={illustration} />
                        <CreateAvatarContainer>
                            <CreateAvatarText>
                                {t('createIdentity')}
                            </CreateAvatarText>
                            <CreateAvatarButton onClick={() => setCreateAvatar(true)}>
                                {t('createAvatar')}
                            </CreateAvatarButton>
                        </CreateAvatarContainer>
                        </>
                :
                null
            }
            {confirmDialogOpen &&
                <SecondPanelBlur />
            }
            <ConfirmationDialog open={confirmDialogOpen}
                onClose={() => { setConfirmDialogOpen(false) }} />
        </SecondPanel>
        <ThirdPanel>
            <ThirdPanelTitle>
                {t('avatarTip')}
            </ThirdPanelTitle>
            <ThirdPanelSubtitle>
                <b>{t('claimAvatar')}</b>{t('toUseIt')}
            </ThirdPanelSubtitle>
        </ThirdPanel>
        </>
    );
}

export default Avatar